<script>
  import appConfig from "@/app.config";
  import PageHeader from "@/components/page-header";
  import store from "@/state/store";
  var state = store.state;
  export default {
    page: {
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    components: {

      PageHeader,
    },
    data() {
      return {
        data: {},
        ArchiveFiles: [],
        archive_files_types: [],
        archive_folders: [],
        selectedCategoryId: 0,
        page: 1,
        limit: 20,
        tot_pages: 0,
        searchArchiveFiles: null,
        ArchiveFilesSearchMode: false,
        searchModel: "",
        state: state,
      };
    },
    computed: {
      archiveCategories() {
        return [
          { id: 0, name: "popups.all_categories" },
          ...this.archive_files_types,
        ];
      },
    },
    watch: {
      // whenever question changes, this function will run
      selectedCategoryId(newQategory) {
        if (newQategory == 0) this.get(1);
        else this.getWithCategoryId(1, newQategory);
      },
    },
    methods: {
      getArchiveFileTypes() {
        this.http.get("archive-file-types").then((res) => {
          this.archive_files_types = res.data;
        });
      },
      getArchiveFolders() {
        this.http.get("archive-folders").then((res) => {
          this.archive_folders = res.data;
        });
      },
      addArchiveFiles() {
        console.log("**********####", this.state);
        var data = {
          title: "popups.addArchiveFiles",
          inputs: [
            {
              model: "file_date",
              type: "date",
              label: "archiving_documents.archiving_documents_table.file_date",
            },
            {
              model: "category_id",
              type: "select",
              options: (() => {
                return this.archive_files_types.map((data) => {
                  return { value: data.id, label: data.name };
                });
              })(),
              label:
                "archiving_documents.archiving_documents_table.category_id",
            },
            {
              model: "archive_folder_id",
              type: "select",
              options: (() => {
                return this.archive_folders.map((data) => {
                  return { value: data.id, label: data.number };
                });
              })(),
              label: "folder_number",
            },
            {
              model: "title",
              type: "text",
              label: "title",
            },
            {
              model: "descr",
              type: "text",
              label: "desc",
            },
            {
              model: "file",
              type: "file",
              label: "file",
            },
          ],
          buttons: [
            {
              text: "popups.add",
              closer: true,
              color: "primary",
              handler: (obj) => {
                console.log(obj);
                this.http.do("archive-files", obj).then(() => {
                  this.selectedCategoryId = 0;
                  this.get(this.page);
                });
              },
            },
            {
              text: "popups.cancel",
              closer: true,
              color: "danger",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.modal(data);
      },
      editArchiveFiles(app) {
        var data = {
          title: "popups.archive_files",
          inputs: [
            {
              model: "file_date",
              type: "date",
              label: "file_date",
              value: app.file_date,
            },
            {
              model: "category_id",
              type: "select",
              options: (() => {
                return this.archive_files_types.map((data) => {
                  return { value: data.id, label: data.name };
                });
              })(),
              label:
                "archiving_documents.archiving_documents_table.category_id",
              value: app.archive_file_type_id,
            },

            {
              model: "archive_folder_id",
              type: "select",
              options: (() => {
                return this.archive_folders.map((data) => {
                  return { value: data.id, label: data.number };
                });
              })(),
              label: "folder_number",
              value: app.archive_folder_id,
            },
            { model: "title", type: "text", label: "title", value: app.title },
            { model: "descr", type: "text", label: "desc", value: app.descr },
            {
              model: "file",
              type: "text",
              label: "old_file",
              value: app.file,
              disabled: true,
            },
            {
              model: "new_file",
              type: "file",
              label: "new_file",
              value: app.new_file,
            },
          ],
          buttons: [
            {
              text: "popups.edit",
              closer: true,
              color: "primary",
              handler: (obj) => {
                obj.archive_files_types_id = obj.category_id;
                obj.id = app.id;
                console.log({ obj });
                this.http[obj.new_file ? "do" : "post"](
                  "archive-files/update",
                  obj
                ).then(() => {
                  this.selectedCategoryId = 0;
                  this.get(this.page);
                });
              },
            },
            {
              text: "popups.cancel",
              closer: true,
              color: "danger",
              handler: () => {
                console.log("Like Clicked");
              },
            },
          ],
        };
        this.popup.modal(data);
      },
      // updateArchiveFiles(app) {
      //   var data = {
      //     title: "popups.editfile",
      //     inputs: [
      //       { model: "file_date", type: "date", label: "file_date" },
      //       {
      //         model: "archive_file_type_id",
      //         type: "text",
      //         label: "archive_file_type_id",
      //       },
      //       { model: "title", type: "text", label: "title" },
      //       { model: "descr", type: "text", label: "descr" },
      //       { model: "file", type: "text", label: "file" },
      //     ],
      //     buttons: [
      //       {
      //         text: "popups.edit",
      //         closer: true,
      //         color: "primary",
      //         handler: (obj) => {
      //           obj.emp_id = app.id;
      //           this.http.do("archive-files", obj).then(() => {
      //             this.get(this.page);
      //           });
      //         },
      //       },
      //       {
      //         text: "popups.cancel",
      //         closer: true,
      //         color: "danger",
      //         handler: () => {
      //           console.log("Like Clicked");
      //         },
      //       },
      //     ],
      //   };
      //   this.popup.modal(data);
      // },
      search() {
        this.ArchiveFilesSearchMode = true;
        this.tot_pages = 0;
        this.http
          .post("archive-files/search", {
            search: this.searchModel,
            limit: this.limit,
            page: this.page,
            col: "title",
          })
          .then((res) => {
            this.selectedCategoryId = 0;
            this.ArchiveFiles = res.data;
            console.log("######## files", this.ArchiveFiles);
          });
      },
      cancelappsearchMode() {
        this.searchModel = "";
        this.ArchiveFilesSearchMode = false;
        this.get(this.page);
      },

      getArchiveFiles() {
        this.http.get("archive-files").then((res) => {
          this.ArchiveFiles = res.data;
        });
      },
      deleteArchiveFiles(app) {
        var data = {
          title: "popups.deletefile",
          msg: "popups.delmessage",
        };
        this.popup.confirm(data).then((resp) => {
          if (resp) {
            console.log("###");
            this.http.post("archive-files/delete", app).then(() => {
              console.log("############################3");
              this.get(this.page);
            });
          }
        });
      },

      get(page) {
        console.log(page);
        this.http
          .post("archive-files/pagination", {
            limit: this.limit,
            page: page,
          })
          .then((res) => {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            console.log({ returnedData: res.data });
            this.ArchiveFiles = res.data;
          });
      },
      getWithCategoryId(page, selectedCatId) {
        console.log(page);
        this.http
          .post("archive-files/filter-by-cat-id/pagination", {
            limit: this.limit,
            page: page,
            archive_file_type_id: selectedCatId,
          })
          .then((res) => {
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
            console.log({ returnedData: res.data });
            this.ArchiveFiles = res.data;
          });
      },
    },
    created() {
      this.getArchiveFileTypes();
      this.getArchiveFolders();
      this.get(1);
    },
  };
</script>
<template>

    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('archiving_documents.text')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="ArchiveFilesSearchMode"
          @click="cancelappsearchMode()"
        ></button>

        <select
          style="
            height: 43px;
            padding-inline: 1rem;
            border: none;
            border-radius: 5px;
            background-color: #eff2f7;
          "
          class="mx-4"
          name="select-filter"
          id="select-filter"
          v-model="selectedCategoryId"
        >
          <option
            v-for="item in archiveCategories"
            :key="item.id"
            :value="item.id"
          >
            {{ item.id == 0 ? $t("popups.all_categories") : item.name }}
          </option>
        </select>
        <button
          type="button"
          @click="addArchiveFiles()"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.id") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.title") }}
          </th>
          <th scope="col">
            {{
              $t("archiving_documents.archiving_documents_table.category_id")
            }}
          </th>
          <th scope="col">
            {{ $t("folder") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.desc") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.file_date") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.created") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.updated") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.file") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.operations") }}
          </th>
          "
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, i) in ArchiveFiles" :key="app" class="text-center">
          <td>{{ i + 1 }}</td>
          <td>{{ app.title }}</td>
          <td>{{ app.archive_file_type?.name }}</td>
          <td>{{ app.archive_folder?.number }}</td>
          <td>{{ app.descr }}</td>

          <td>{{ app.file_date?.split("T")[0] }}</td>
          <td>{{ app.created.split("T")[0] }}</td>
          <td>{{ app.updated.split("T")[0] }}</td>
          <td>
            <a
              target="_blank"
              :href="`${$store.state.authfack.imgUrl}${
                app.file.split('./uploads/')[1]
              }`"
              download
              >{{ $t("popups.download") }}</a
            >
          </td>
          <td class="d-flex align-items-center justify-content-center">
            <a
              class="btn btn-primary mx-1"
              @click="editArchiveFiles(app)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.edit") }}</a
            >
            <a
              class="btn btn-danger"
              href="javascript: void(0);"
              role="button"
              @click="deleteArchiveFiles(app)"
              >{{ $t("popups.delete") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  Pagination     -->
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->

</template>

<style scoped>
  .btn-close {
    position: relative;
    right: 317px;
    top: 6px;
    font-size: 15px;
  }
</style>
